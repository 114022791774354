<template>
  <div :id="videoContainer" class="video-player" />
</template>
<script>
import Player from 'xgplayer';

export default {
  name: 'XgVideoPlayer',
  props: {
    videoContainer: {
      // 父组件传过来的视频id
      type: String,
      default: 'videoId',
    },
    url: {
      // 父组件传过来的视频链接
      type: String,
      default: '',
    },
    poster: {
      // 父组件传过来的封面链接
      type: String,
      default: '',
    },
  },
  data() {
    return {
      player: null, // 实例
    };
  },
  // 监听播放路径的变化
  watch: {
    url(newVal) {
      if (!this.player) {
        this.initPlayer();
      }
      this.player.src = newVal;
    },
  },
  mounted() {
    this.initPlayer();
  },
  methods: {
    // =========================1，设置播放器必要参数===================
    initPlayer() {
      if (!this.url) return console.warn('url is not esist');
      const videoInit = !this.poster;
      const config = {
        id: this.videoContainer,
        width: 2,
        height: 1,
        url: this.url,
        poster: this.poster,
        fluid: true,
        /** 倍速播放 */
        playbackRate: [0.5, 0.75, 1, 1.5, 2],
        defaultPlaybackRate: 1,
        /** 初始化首帧 */
        videoInit,
        autoplay: false,

      };
      //= ========================= 2，开始实例化======================
      this.player = new Player(config);
    },
 
  
  },
};
</script>

<style></style>

